/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from "react";
import { TypeReportEnum } from "../../../api";

interface ReportFilterContextProps {
  selectedPeriodType: TypeReportEnum;
  setSelectedPeriodType: (type: TypeReportEnum) => void;
  selectedReportType: string;
  setSelectedReportType: (type: string) => void;
}

const ReportFilterContext = createContext<ReportFilterContextProps | undefined>(undefined);

export const ReportFilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedPeriodType, setSelectedPeriodType] = useState<TypeReportEnum>(TypeReportEnum.DAY);
  const [selectedReportType, setSelectedReportType] = useState<string>("script");

  return (
    <ReportFilterContext.Provider
      value={{
        selectedPeriodType,
        setSelectedPeriodType,
        selectedReportType,
        setSelectedReportType,
      }}
    >
      {children}
    </ReportFilterContext.Provider>
  );
};

export const useReportFilter = () => {
  const context = useContext(ReportFilterContext);
  if (!context) {
    throw new Error("useReportFilter must be used within a ReportFilterProvider");
  }
  return context;
};
