import { LogoutOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const menuItems = [
  // {
  //   key: "1",
  //   icon: <SettingOutlined />,
  //   label: "Настройки",
  // },
  {
    key: "2",
    icon: <LogoutOutlined />,
    label: "Выйти из аккаунта",
  },
];
interface MenuItemsProps {
  className?: string;
  style?: React.CSSProperties;
  handleLogout?: () => void;
}
const MenuItems: React.FC<MenuItemsProps> = ({ className, style, handleLogout }) => {
  const navigate = useNavigate();

  // const handleLogout = () => {
  //   // Логика для выхода из аккаунта
  // };
  const updatedItems = menuItems.map((item) => ({
    ...item,
    onClick: item.key === "1" ? () => navigate("/settings") : handleLogout,
  }));
  return <Menu className={className} style={style} items={updatedItems} />;
};

export { MenuItems };
