import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";

const MainLayout = lazy(() => import("../../layouts/MainLayout/MainLayout"));
const CompanyList = lazy(() => import("../../modules/Admin/pages/Company/List"));
const CompanyCreate = lazy(() => import("../../modules/Admin/pages/Company/Create"));
const CompanyDetails = lazy(() => import("../../modules/Admin/pages/Company/Details"));
const EmployeesList = lazy(() => import("../../modules/Admin/pages/Company/EmployeesList"));
const DepartmentsList = lazy(() => import("../../modules/Admin/pages/Company/DepartmentsList"));
const EditUserProfile = lazy(() => import("../../pages/Profile/EditUserProfile"));
const TrainingBase = lazy(() => import("../../pages/Admin/TrainingBase/TrainingBase"));

const AdminRoutes: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "login",
        element: <Navigate to="/admin" replace />,
      },
      {
        // path: "/admin",
        index: true,
        element: <CompanyList />,
      },
      {
        path: "company/create/:id",
        element: <CompanyCreate />,
      },
      {
        path: "company/:id",
        element: <CompanyDetails />,
      },
      {
        path: "company/:id/employees",
        element: <EmployeesList />,
      },
      {
        path: "company/:id/departments",
        element: <DepartmentsList />,
      },
      {
        path: "company/create",
        element: <CompanyCreate />,
      },
      {
        path: "profile",
        element: <EditUserProfile />,
      },
      {
        path: "appeals",
        element: <h1>Обращения</h1>,
      },
      {
        path: "moderation",
        element: <h1>Модерация</h1>,
      },
      {
        path: "feedback",
        element: <h1>Обратная связь</h1>,
      },
      {
        path: "training-base",
        element: <TrainingBase />,
      },
    ],
  },
];

export default AdminRoutes;
