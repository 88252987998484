/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */

/**
 * * `Day` - Day
 * * `Week` - Week
 * * `Month` - Month
 */
export enum TypeReportEnum {
  DAY = "Day",
  WEEK = "Week",
  MONTH = "Month",
}
