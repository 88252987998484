import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { ReportFilterProvider } from "../../pages/Owner/Reports/ReportFilterContext";

const MainLayout = lazy(() => import("../../layouts/MainLayout/MainLayout"));
const Analytics = lazy(() => import("../../pages/Owner/Analytics/Analytics"));
const Employees = lazy(() => import("../../pages/Owner/Employees/Employees"));
const Reports = lazy(() => import("../../pages/Owner/Reports/Reports"));
const ComplianceSales = lazy(
  () => import("../../pages/Owner/Reports/ComplianceSales/ComplianceSales"),
);
const ComplianceScript = lazy(
  () => import("../../pages/Owner/Reports/ComplianceScript/ComplianceScript"),
);
const ManagerProgress = lazy(
  () => import("../../pages/Owner/Reports/ManagerProgress/ManagerProgress"),
);
const ReportsLayout = lazy(() => import("../../pages/Owner/Reports/ReportsLayout"));
const Rnp = lazy(() => import("../../pages/Owner/Rnp/Rnp"));
const UserManual = lazy(() => import("../../pages/Owner/UserManual/UserManual"));

const OwnerRoutes: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "login",
        element: <Navigate to="/owner" replace />,
      },
      {
        index: true,
        element: <UserManual />,
      },
      {
        path: "/reports",
        element: (
          <ReportFilterProvider>
            <ReportsLayout />
          </ReportFilterProvider>
        ),
        children: [
          {
            index: true,
            element: <Reports />,
          },
          {
            path: "script/:id",
            element: <ComplianceScript />,
          },
          {
            path: "postgres/:id",
            element: <ManagerProgress />,
          },
          {
            path: "sales/:id",
            element: <ComplianceSales />,
          },
        ],
      },
      {
        path: "/rnp",
        element: <Rnp />,
      },
      {
        path: "/employees",
        element: <Employees />,
      },
      {
        path: "/analytics",
        element: <Analytics />,
      },
    ],
  },
];

export default OwnerRoutes;
